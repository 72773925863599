exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-connect-with-us-js": () => import("./../../../src/pages/connect-with-us.js" /* webpackChunkName: "component---src-pages-connect-with-us-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-create-js": () => import("./../../../src/pages/create.js" /* webpackChunkName: "component---src-pages-create-js" */),
  "component---src-pages-dex-js": () => import("./../../../src/pages/dex.js" /* webpackChunkName: "component---src-pages-dex-js" */),
  "component---src-pages-embedded-audited-kyced-js": () => import("./../../../src/pages/embedded-audited-kyced.js" /* webpackChunkName: "component---src-pages-embedded-audited-kyced-js" */),
  "component---src-pages-embedded-certified-js": () => import("./../../../src/pages/embedded-certified.js" /* webpackChunkName: "component---src-pages-embedded-certified-js" */),
  "component---src-pages-exchange-js": () => import("./../../../src/pages/exchange.js" /* webpackChunkName: "component---src-pages-exchange-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-hedgetalk-js": () => import("./../../../src/pages/hedgetalk.js" /* webpackChunkName: "component---src-pages-hedgetalk-js" */),
  "component---src-pages-help-center-js": () => import("./../../../src/pages/help-center.js" /* webpackChunkName: "component---src-pages-help-center-js" */),
  "component---src-pages-ido-js": () => import("./../../../src/pages/ido.js" /* webpackChunkName: "component---src-pages-ido-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kycaudit-js": () => import("./../../../src/pages/kycaudit.js" /* webpackChunkName: "component---src-pages-kycaudit-js" */),
  "component---src-pages-kycform-js": () => import("./../../../src/pages/kycform.js" /* webpackChunkName: "component---src-pages-kycform-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-rewards-js": () => import("./../../../src/pages/rewards.js" /* webpackChunkName: "component---src-pages-rewards-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-team-old-js": () => import("./../../../src/pages/team - old.js" /* webpackChunkName: "component---src-pages-team-old-js" */),
  "component---src-pages-term-ilo-js": () => import("./../../../src/pages/term-ilo.js" /* webpackChunkName: "component---src-pages-term-ilo-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-transak-js": () => import("./../../../src/pages/transak.js" /* webpackChunkName: "component---src-pages-transak-js" */),
  "component---src-pages-transfer-js": () => import("./../../../src/pages/transfer.js" /* webpackChunkName: "component---src-pages-transfer-js" */),
  "component---src-pages-vaults-js": () => import("./../../../src/pages/vaults.js" /* webpackChunkName: "component---src-pages-vaults-js" */),
  "component---src-pages-wechat-js": () => import("./../../../src/pages/wechat.js" /* webpackChunkName: "component---src-pages-wechat-js" */),
  "component---src-pages-whitepaper-js": () => import("./../../../src/pages/whitepaper.js" /* webpackChunkName: "component---src-pages-whitepaper-js" */)
}

