import BigNumber from 'bignumber.js';
import { parseEther } from 'ethers/lib/utils';
import HegeCoin from "hpay/contracts/HedgeCoin.json";
import { useCallback, useEffect, useMemo, useState } from "react";
import { printError } from '../utils/utils';
import { ContractFactoryHof } from "./contracts/contract.factory";
import { getNativeCoin } from './token-utils';
import { getProvider } from './web3';


export async function fetchTokenBalance(tokenAddress, address, chainId = 56, block = 'latest') {
    try {
        // console.log("Fetching balance::chainid", chainId, "::token", tokenAddress, "::address", address, "block::", block);
        const provider = getProvider(chainId);
        const native = getNativeCoin(chainId);
        if (tokenAddress === native.address) {
            return getBalance('BNB', address, block);
        }

        const contract = await ContractFactoryHof(provider).create(HegeCoin.abi, tokenAddress);
        const balance = await contract.methods.balanceOf(address).call(null,  block);
        const decimals = await contract.methods.decimals().call();
        return +new BigNumber(balance).div(10 ** decimals).toFixed(8, 3);
    } catch (error) {
        printError(error);
        // console.log("Clould not fetch balance", chainId);
        return 0;
    }
};

export async function getRawBalance(contractName, address, block = 'latest') {
    if (contractName === 'BNB') {
        const balance = await window.web3.eth.getBalance(address, block);
        return { value: Number(balance), decimals: 18 }
    }
}

export async function getBalance(contractName, address, block) {
    const { value, decimals } = await getRawBalance(contractName, address, block);
    return Number(value) / 10 ** decimals;
}


export const useApproveCheck = (networkId) =>
    useCallback((account, spenderAddress, tokenAddress) =>
        async (amount = '9999999') => {
            const nativeToken = getNativeCoin(networkId);
            try {
                const contract = ContractFactoryHof(window.web3).create(HegeCoin.abi, tokenAddress);
                const decimals = await contract.methods.decimals().call();
                // console.log("Checking allowance for", tokenAddress, " from::", account, " on netwotk", networkId, "  from::", spenderAddress);
                // console.log("NativeTOken", nativeToken.address);

                if (
                    tokenAddress === nativeToken.address || amount === 0
                ) {
                    // console.log("contract is bnb::")
                    return true;
                }
                if (contract && account && spenderAddress) {
                    const allowance = await contract.methods
                        .allowance(account, spenderAddress)
                        .call();

                    // console.log("Allowance is::", +allowance, +parseEther(amount));

                    const result = parseEther(amount).lte(allowance);
                    return result;
                }
            } catch (error) {
                console.error("Could not check allowance for contract::", tokenAddress)
                console.log(error);
            }

            return false;
        }, [networkId])

export const useApproveRequest = (networkId) => useCallback(
    (account, spenderAddress, tokenAddress, callback) =>
        async (amount = '9999999999999999999999999') => {
            try {
                const contract = ContractFactoryHof(window.web3).create(HegeCoin.abi, tokenAddress);
                // const decimals = await contract.methods.decimals();

                const _amount = parseEther('' + amount);
                // console.log("Approving:::", account, _amount.toString());
                const gasPrice = await window.web3.eth.getGasPrice();
                let args = {
                    from: account,
                    gasPrice: gasPrice,
                    value: 0,
                };

                await contract.methods
                    .approve(spenderAddress, _amount.toString())
                    .send(args);

                if (callback) {
                    callback(true);
                }

                return true;
            } catch (error) {
                console.error("Could not submit approve for contract::", tokenAddress)
                console.log(error);
            }

            if (callback) {
                callback(false);
            }
            return false;
        }, [networkId])

export const useApprove = (tokenAddress, account, spenderAddress, networkId = 56) => {
    const [isApproved, setIsApproved] = useState(true);
    const _check = useApproveCheck(networkId);
    const _approve = useApproveRequest(networkId);

    const checkApproved = useMemo(() => {
        if (!spenderAddress || !tokenAddress || !account) {
            return;
        }
        return _check(account, spenderAddress, tokenAddress);
    }, [spenderAddress, tokenAddress, account]);

    const approve = useMemo(() => {
        if (!spenderAddress || !tokenAddress || !account) {
            return;
        }
        return _approve(account, spenderAddress, tokenAddress, (status) => setIsApproved(status))
    }, [spenderAddress, tokenAddress, account]);

    useEffect(() => {
        if (!checkApproved) {
            return;
        }
        checkApproved().then(setIsApproved)
    }, [checkApproved]);

    return { approve, isApproved, checkApproved };
};