import { createSlice } from '@reduxjs/toolkit';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { useIdo as useFairLaunch } from '../web3/fair-launch';
import { getIdoStatus, useBalanceAmount, useIdo, useInvestedAmount, useIsAdmin, useIsWhiteListed } from '../web3/idos';
import { useAccount } from './account';
import Idos from "hpay/content/Idos.json";

export const idosSlice = createSlice({
    name: 'idos',
    initialState: {
        idos: [],
        paginate: {},
        currentIdo: null,
        investedAmount: 0,
        userBalance: 0,
        isWhitelisted: true,
        isAdmin: false,
    },
    reducers: {
        setPaginationProps: (state, action) => {
            state.paginate = action.payload;
        },
        setIdos: (state, action) => {
            state.idos = action.payload;
        },
        setCurrentIdo: (state, action) => {
            state.currentIdo = action.payload;
        },
        setInitialIdo: (state, action) => {
            state.currentIdo = action.payload;
        },
        setInvestedAmount: (state, action) => {
            state.investedAmount = action.payload;
        },
        setIsWhilteListed: (state, action) => {
            state.isWhitelisted = action.payload;
        },
        setUserBalance: (state, action) => {
            state.userBalance = action.payload;
        },
        setIsAdmin: (state, action) => {
            state.isAdmin = action.payload;
        },
    },
});

export const { setIdos, setInitialIdo, setPaginationProps, setCurrentIdo, setInvestedAmount, setUserBalance, setIsWhilteListed, setIsAdmin } = idosSlice.actions;

export const useIdoData = (address) => {
    const fetchData = useIdo(address);
    const fetchFairLaunchData = useFairLaunch(address);

    const [data, setData] = useState();

    useEffect(() => {
        const fn = async () => {
            const ido = Object.values(Idos).find(item => item.address === address);
            let data;
            if (ido.type === "FAIRLAUNCH") {
                data = await fetchFairLaunchData(address)
            } else {
                data = await fetchData(address);
            }
            setData({ ...ido, ...data });
        }
        fn();
    }, [address, setData, fetchData, fetchFairLaunchData]);
    return data;
};

export const useIdos = () => {
    const [status, refresh] = useRefreshIdos();
    const selector = useSelector(state => ({
        idos: state.idos.idos,
        paginate: state.idos.paginate,
        status,
        onPageClick: refresh,

    }));

    const onSearchByName = useCallback(({ target: { value } }) => {
        const { limit } = selector.paginate;
        return refresh({ page: 1, limit, search: value });
    }, [refresh, selector.paginate]);

    useEffect(() => {
        refresh({ page: 1 });
    }, [refresh]);

    return { ...selector, onSearchByName };
};

export const useCurrentIdo = (address) => {
    const [status, refresh] = useRefreshIdo();

    const selector = useSelector(state => ({
        ido: state.idos.currentIdo, status, refresh
    }));

    useEffect(() => {
        refresh(address);
    }, [refresh, address]);

    return selector;
}

export const useUserIsAdmin = (address) => {
    const account = useAccount();
    const [status, refresh] = useRefreshUserIsAdmin(address);

    const selector = useSelector(state => ({
        isAdmin: state.idos.isAdmin, status
    }));

    useEffect(() => {
        refresh(account);
    }, [refresh, account]);
    return selector;
};

export const useIsUserWhiteListed = (address) => {
    const account = useAccount();
    const [status, refresh] = useRefreshWhilteListed(address);

    const selector = useSelector(state => ({
        isWhitelisted: state.idos.isWhitelisted, status
    }));

    useEffect(() => {
        refresh(account);
    }, [refresh, account, address]);
    return selector;
};

export const useUserBalanaceAmount = (idoAddress) => {
    const account = useAccount();
    const [status, refresh] = useRefreshUserBalanceAmount(idoAddress);

    const selector = useSelector(state => ({
        userBalance: state.idos.userBalance, status
    }));

    useEffect(() => {
        refresh(account);
    }, [refresh, account, idoAddress]);
    return selector;
};

export const useUserInvestment = (idoAddress) => {
    const account = useAccount();
    const [status, refresh] = useRefreshInvestmentAmount(idoAddress);

    const selector = useSelector(state => ({
        investedAmount: state.idos.investedAmount, status
    }));

    useEffect(() => {
        refresh(account);
    }, [refresh, account, idoAddress]);
    return selector;
};

export const useRefreshUserBalanceAmount = (idoAddress) => {
    const dispatch = useDispatch();
    const fetchData = useBalanceAmount(idoAddress);

    return useAsyncFn(async (account) => {
        const amount = await fetchData(account);

        dispatch(setUserBalance(amount));
    }, [dispatch, fetchData, idoAddress]);
};

export const useRefreshUserIsAdmin = (idoAddress) => {
    const dispatch = useDispatch();
    const fetchData = useIsAdmin(idoAddress);

    return useAsyncFn(async (account) => {
        const status = await fetchData(account);
        dispatch(setIsAdmin(status));
    }, [dispatch, fetchData, idoAddress]);
};

export const useRefreshWhilteListed = (idoAddress) => {
    const dispatch = useDispatch();
    const fetchData = useIsWhiteListed(idoAddress);

    return useAsyncFn(async (account) => {
        const status = await fetchData(account);
        dispatch(setIsWhilteListed(status));
    }, [dispatch, fetchData, idoAddress]);
};

export const useRefreshInvestmentAmount = (idoAddress) => {
    const dispatch = useDispatch();
    const fetchData = useInvestedAmount(idoAddress);

    return useAsyncFn(async (account) => {
        const amount = await fetchData(account);
        dispatch(setInvestedAmount(amount));
    }, [dispatch, fetchData]);
};

export const useRefreshIdos = () => {
    const dispatch = useDispatch();

    return useAsyncFn(async ({ page = 1, limit = 6, search = "" }) => {
        const start = limit * (page - 1);
        const finish = start + limit;

        const idos = Object.entries(Idos)
            .filter(([, value]) => idoFilter(search)(value))
            .map(([key, ido]) => ({ ...ido, location: key }))

        const pageCount = Math.ceil(idos.length / (limit));
        dispatch(setPaginationProps({
            pageCount
        }));

        dispatch(setIdos(idos.slice(start, finish)));

    }, [dispatch]);
};

const idoFilter = (searchValue) => (ido) => {

    if (!ido.enabled) {
        return false;
    }

    if (searchValue.length === 0) {
        return true;
    }

    return [ido.name, ido.description, ido.owner, ido.ticker].join(' ').toLocaleLowerCase().includes(searchValue);
}

export const useRefreshIdo = () => {
    const dispatch = useDispatch();
    const fetchIdo = useIdo();
    const fetchFairLaunch = useFairLaunch();
    const store = useStore();

    return useAsyncFn(async (address) => {
        let ido = Object.values(Idos).find(item => item.address === address);
        const _prevIdo = store.getState().idos.currentIdo;

        const status = getIdoStatus(ido, Date.now());
        if (!_prevIdo || _prevIdo.address !== address) {
            dispatch(setInitialIdo({ ...ido, status }));
        }
        if (ido.mocked) {
            return;
        }

        let chainIdo;
        if (ido.type === "FAIRLAUNCH") {
            chainIdo = await fetchFairLaunch(address);
        } else {
            chainIdo = await fetchIdo(address);
        }

        dispatch(setCurrentIdo({ ...ido, ...chainIdo }));
    }, [dispatch, store]);
};

export default idosSlice.reducer;