import { useCallback, useEffect, useState } from "react";

import { RouterABI } from "./abi/routera.abi";
import { Tokens, useContract } from "./contracts/contracts";
import { ContractFactoryHof } from './contracts/contract.factory';
import { getProvider } from './web3';
import { getNativeCoin } from './token-utils';
import { computeTrade, getDefaultRouterAddress, printError } from '../utils/utils';
import SwapperConfigurations from "../../static/exchange/swappers.json";
import { useAsyncFn } from 'react-use';

export const fetchPrice = async (tokenA, tokenB, router, chainId) => {
    // console.log("Fetcing price::", tokenA, tokenB, router, chainId)
    if (!tokenA || !tokenB || !chainId || !router) {
        return;
    }
    if (tokenA.address === tokenB.address) {
        return 1
    }

    const provider = getProvider(chainId)
    const nativeCoin = getNativeCoin(chainId);
    const routerContract = await ContractFactoryHof(provider).create(RouterABI, router);

    let path = [tokenA.address, nativeCoin.address, tokenB.address];
    if (tokenA.address === nativeCoin.address || tokenB.address === nativeCoin.address) {
        path = [tokenA.address, tokenB.address];
    }

    path.reverse();
    if (routerContract) {
        try {
            const amountOut = await routerContract.methods
                .getAmountsOut((1 * 10 ** tokenB.decimals).toString(), path)
                .call();

            const amountFormatted = amountOut[path.length - 1] / 10 ** (tokenA.decimals);
            // console.log(` For 1 ${tokenB.symbol} you get`, amountFormatted, tokenA.symbol, chainId);
            return amountFormatted;
        } catch (error) {
            // console.log("Could not get relative token price::chainId ", chainId, "::router", router)
        }
    }
}

export const useRelativeTokenPrice = (tokenA, tokenB, router, chainId) => {
    const [price, setPrice] = useState(1);

    const refreshPrice = useCallback(() => {
        if (refreshPrice) {
            fetchPrice(tokenA, tokenB, router, chainId).then(setPrice);
        }
    }, [tokenA, tokenB, router, chainId]);

    useEffect(() => {
        refreshPrice();
    }, [refreshPrice]);

    return [price, refreshPrice];
};

export const useTokenPrice = (networkId) => {
    const fetchPrice = useCallback(async (path) => {
        const provider = getProvider(networkId)
        const routerAddress = getDefaultRouterAddress(networkId)
        const routerContract = await ContractFactoryHof(provider).create(RouterABI, routerAddress);

        if (routerContract) {
            // console.log("Getting price for::", networkId, path, routerAddress)
            const amountOut = await routerContract.methods
                .getAmountsOut(
                    (1 * 10 ** path[0].decimals).toString(), path.map(item => item.address)
                )
                .call().catch(err => {
                    console.log("Fecth Price Err:::", err);
                });

            if (!amountOut) {
                return;
            }
            const result = (+amountOut[amountOut.length - 1]) / (10 ** (path[path.length - 1].decimals));
            // console.log("OUT:::Getting price for::", result);

            return result;
        }

    }, [networkId]);

    return fetchPrice;
};

export const useBestPrice = (amount, tokenA, tokenB) => {
    const [price, setPrice] = useState();
    const [diff, setDiff] = useState(0);
    const [error, setError] = useState(false);

    const [swapper, setSwapper] = useState();

    const [status, refreshPrice] = useAsyncFn(async () => {
        if (fetchPrice) {
            return fetchBestPrice(amount, tokenA, tokenB).then(data => {
                setPrice(data.price);
                setDiff(data.diff);
                setSwapper(data.router);
                setError(false);
                return data.price;
            }).catch(() => {
                setError(true);
            });
        }
    }, [tokenA, tokenB, amount])

    useEffect(() => {
        refreshPrice()
    }, [refreshPrice]);

    return { price, diff, refreshPrice, status, swapper, error };
}


export const fetchBestPrice = async (amount, tokenA, tokenB) => {
    if (!tokenA || !tokenB || !amount) {
        return;
    }

    if (!tokenA.chainId !== !tokenB.chainId) {
        return;
    }
    if (tokenA.address === tokenB.address) {
        return;
    }

    const chainId = tokenA.chainId;
    const routers = Object.entries(SwapperConfigurations).filter(([_, swapper]) => +swapper.chainId === +chainId && swapper.enabled);

    const prices = (await Promise.all(
        routers.map(async ([key, swapper]) => {
            try {
                const priceInfo = await computeTradeDetails(amount, tokenA, tokenB, swapper)
                return { ...priceInfo, exchange: key, router: swapper }
            } catch {
                console.log("Could not get rate for ", key)
            }
        })
    )).filter(item => item.price);

    if (!prices || prices.length === 0) {
        throw "bestPrice/not-found";
    }

    // console.log("======", tokenA.symbol, tokenB.symbol)
    // console.log(prices.map(item => `${item.exchange} @ ${item.price}`).join('\n'));
    // console.log("======")

    const bestPrice = prices.reduce((prev, curr) => {
        return Number(prev.price) > Number(curr.price) ? prev : curr;
    }, { price: 0 });

    const totalPrice = prices.reduce((prev, curr) => {
        return prev += +curr.price
    }, 0);

    const averagePrice = totalPrice / prices.length

    // console.log("AVG PRICE :::", averagePrice)
    // console.log("BEST PRICE IS ON:::", bestPrice.exchange, bestPrice.price)

    let diff = prices.length > 1 ? 100 * Math.abs(averagePrice - +bestPrice.price) / averagePrice : 0;

    if (!tokenA.unverified && !tokenB.unverified) {
        if (tokenA.proxy) {
            diff += tokenA.sellFeeOriginal - tokenA.sellFee;
            // console.log("Showing proxy fee sell, ", tokenA.sellFeeOriginal - tokenA.sellFee);
        } else if (tokenB.proxy) {
            diff += tokenB.buyFeeOriginal - tokenB.buyFee;
            // console.log("Showing proxy fee buy, ", tokenB.buyFeeOriginal - tokenB.buyFee);
        }
    }

    // console.log(bestPrice);

    return {
        ...bestPrice,
        averagePrice,
        diff: diff.toFixed(4)
    };
}

/**
 * 
 * @param {number} amountA Amount in
 * @param {import('@pancakeswap/sdk').Token} tokenA Token in 
 * @param {import('@pancakeswap/sdk').Token} tokenB Token out
 * @param {any} swapper 
 * @returns 
 */
export const computeTradeDetails = async (amountA, tokenA, tokenB, swapper) => {
    try {
        const nativeCoin = getNativeCoin(tokenA.chainId);

        if (tokenA.chainId !== tokenB.chainId) {
            console.log("Tokens are not on the same chain");
            return;
        }

        let path;
        if (tokenA.symbol !== nativeCoin.symbol && tokenB.symbol !== nativeCoin.symbol) {
            path = [tokenA, nativeCoin, tokenB];
        } else {
            path = [tokenA, tokenB];
        }

        const trade = await computeTrade(amountA, path, swapper);

        if (!trade) {
            return;
        }

        // console.log("Swapper fee", swapper.fee);
        const slippage = Number(trade.priceImpact.toFixed(2)) + Number(swapper.fee);
        // console.log("Computed Trade::", trade, path);
        return {
            price: trade.executionPrice.toFixed(+tokenB.decimals),
            slippage,
            path
        }
    } catch (err) {
        console.log(err)
        printError(err);
        // console.log(`Could not load price data for ${swapper.router}@${swapper.chainId}`)
    }
}
