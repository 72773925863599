import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { useAsyncFn } from 'react-use';
import { useIsAdmin, useUserAmounts, useUserWhitelisted, useVault } from '../web3/vault-staking';
import { useAccount } from './account';
import Vaults from "hpay/content/Vaults.json";
import { useVault as useAutoCompoundVault } from '../web3/autocompound-relock';

export const vaultStakingSlice = createSlice({
    name: 'vaultStaking',
    initialState: {
        vaults: [],
        paginate: {},
        currentVault: null,
        userLocked: 0,
        userEarned: 0,
        userEarnedReflection: 0,
        userUnlockTime: 0,
        isUnlocked: false,
        isWhitelisted: true,
        isAdmin: false
    },
    reducers: {
        setVaults: (state, action) => {
            state.vaults = action.payload;
        },
        setCurrentVault: (state, action) => {
            state.currentVault = action.payload;
        },
        setUserLocked: (state, action) => {
            state.userLocked = action.payload;
        },

        setUserIsUnlocked: (state, action) => {
            state.isUnlocked = action.payload;
        },

        setIsAdmin: (state, action) => {
            state.isAdmin = action.payload;
        },

        setUserEarnedReflection: (state, action) => {
            state.userEarnedReflection = action.payload;
        },

        setIsWhitelisted: (state, action) => {
            state.isWhitelisted = action.payload;
        },

        setUserEarned: (state, action) => {
            state.userEarned = action.payload;
        },
        setUserUnlockTime: (state, action) => {
            state.userUnlockTime = action.payload;
        },
        setPaginationProps: (state, action) => {
            state.paginate = action.payload;
        },
    },
});

export const { setCurrentVault, setPaginationProps, setVaults, setIsWhitelisted, setIsAdmin, setUserEarnedReflection, setUserLocked, setUserEarned, setUserUnlockTime, setUserIsUnlocked } = vaultStakingSlice.actions;

export const useVaults = () => {
    const [status, refresh] = useRefreshVaults();
    const selector = useSelector(state => ({
        vaults: state.vaultStaking.vaults,
        paginate: state.vaultStaking.paginate,
        status,
        onPageClick: refresh,
    }));

    const onSearchByName = useCallback(({ target: { value } }) => {
        const { limit } = selector.paginate;
        return refresh({ page: 1, limit, search: value });
    }, [refresh, selector.paginate]);

    useEffect(() => {
        refresh({ page: 1 });
    }, [refresh]);

    return { ...selector, onSearchByName };
};

export const useRefreshVaults = () => {
    const dispatch = useDispatch();

    return useAsyncFn(async ({ page = 1, limit = 6, search = "" }) => {
        const start = limit * (page - 1);
        const finish = start + limit;
        const vaults = Object.entries(Vaults)
            .filter(([, value]) => vaultFilter(search)(value))
            .map(([key, vault]) => ({ ...vault, location: key }))

        const pageCount = Math.ceil(vaults.length / (limit));

        dispatch(setPaginationProps({
            pageCount
        }));
        dispatch(setVaults(vaults.slice(start, finish)));
    }, [dispatch]);
};

const vaultFilter = (searchValue) => (ido) => {
    if (!ido.enabled) {
        return false;
    }

    if (searchValue.length === 0) {
        return true;
    }

    return [ido.name, ido.description, ido.owner, ido.ticker].join(' ').toLocaleLowerCase().includes(searchValue);
}
export const useCurrentVault = (address) => {
    const [status, refresh] = useRefreshCurrentVault();

    const selector = useSelector(state => ({
        currentVault: state.vaultStaking.currentVault, status
    }));

    useEffect(() => {
        refresh(address);
    }, [refresh, address]);

    return selector;
};

export const useUserIsAdmin = (address) => {
    const account = useAccount();
    const [status, refresh] = useRefreshUserIsAdmin(address);

    const selector = useSelector(state => ({
        isAdmin: state.vaultStaking.isAdmin, status
    }));

    useEffect(() => {
        // console.log("Checking is admin", account);
        refresh(account);
    }, [refresh, account]);
    return selector;
};


export const useVaultData = (address) => {
    const fetchData = useVault();
    const fetchAutoCompound = useAutoCompoundVault();
    const [data, setData] = useState();

    useEffect(() => {
        (async function () {
            const vault = Object.values(Vaults).find(item => item.address === address);

            let fetch = fetchData;
            if (vault.type === 'AUTOCOMPOUND_RELOCK') {
                fetch = fetchAutoCompound;
            }

            const data = await fetch(address);
            setData({ ...vault, ...data });
        })()
    }, [address, setData, fetchData, fetchAutoCompound]);

    return data;
};


export const useRefreshCurrentVault = () => {
    const dispatch = useDispatch();
    const fetchData = useVault();
    const fetchAutoCompound = useAutoCompoundVault();

    return useAsyncFn(async (address) => {
        const vault = Object.values(Vaults).find(item => item.address === address);
        if (!vault.enabled) {
            return;
        }

        let fetch = fetchData;
        if (vault.type === 'AUTOCOMPOUND_RELOCK') {
            fetch = fetchAutoCompound;
        }

        const data = await fetch(address);
        if (vault.mocked) {
            const time = Date.parse(vault.details.time);
            dispatch(setCurrentVault({ ...vault.details, ...vault, time }));
        } else {
            dispatch(setCurrentVault({ ...vault.details, ...vault, ...data }));
        }

    }, [dispatch, fetchData, setCurrentVault, fetchAutoCompound]);
};

export const useUserIsWhitelisted = (vault) => {
    const account = useAccount();
    const [status, refresh] = useRefreshUserIsWhitelisted(vault);

    const selector = useSelector(state => ({
        isWhitelisted: state.vaultStaking.isWhitelisted,
        status
    }));

    useEffect(() => {
        refresh(account);
    }, [refresh, account, vault]);
    return selector;
};

export const useRefreshUserIsWhitelisted = (vault) => {
    const dispatch = useDispatch();
    const fetchData = useUserWhitelisted(vault);

    return useAsyncFn(async (account) => {
        const result = await fetchData(account);
        dispatch(setIsWhitelisted(result));
    }, [dispatch, fetchData, vault]);
};

export const useUserVaultAmounts = (vault) => {
    const account = useAccount();
    const [status, refresh] = useRefreshUserAmount(vault);

    const selector = useSelector(state => ({
        userLocked: state.vaultStaking.userLocked,
        userEarned: state.vaultStaking.userEarned,
        userUnlockTime: state.vaultStaking.userUnlockTime,
        isUnlocked: state.vaultStaking.isUnlocked,
        userEarnedReflection: state.vaultStaking.userEarnedReflection,
        status
    }));

    useEffect(() => {
        refresh(account);
    }, [refresh, account, vault]);
    return selector;
};

export const useRefreshUserAmount = (vault) => {
    const dispatch = useDispatch();
    const fetchData = useUserAmounts(vault);

    return useAsyncFn(async (account) => {
        const [reward, balance, lock, unlocked, reflections] = await fetchData(account);
        dispatch(setUserLocked(balance));
        dispatch(setUserEarned(reward));
        dispatch(setUserUnlockTime(lock));
        dispatch(setUserEarnedReflection(reflections));
        dispatch(setUserIsUnlocked(unlocked));

    }, [dispatch, fetchData, vault]);
};



export const useRefreshUserIsAdmin = (vaultAddress) => {
    const dispatch = useDispatch();
    const fetchData = useIsAdmin(vaultAddress);

    return useAsyncFn(async (account) => {
        const status = await fetchData(account);
        dispatch(setIsAdmin(status));
    }, [dispatch, fetchData, setIsAdmin]);
};

export default vaultStakingSlice.reducer;