import Idos from "hpay/content/Idos.json";
import Ido from "hpay/contracts/Ido.json";
import { useCallback } from "react";
import { useRefreshIdo, useRefreshInvestmentAmount, useRefreshUserBalanceAmount } from "../state/idos";
import { ZERO_ADDRESS } from '../utils/utils';
import { ContractFactoryHof } from "./contracts/contract.factory";
import { useContractWithProvider } from "./contracts/contracts";
import { createIdoWeb3, getBlockTime } from "./web3";
import { getNativeCoin } from "./token-utils";

export const useIdoContract = (address, provider = window.web3) => {
    const contract = useContractWithProvider(address, Ido.abi, provider);
    return contract;
};

export const getIdoStatus = (ido, blockTime) => {
    let status = ido.status;
    if (ido.startTime > blockTime) {
        status = 0; //started
    }

    if (ido.startTime <= blockTime && ido.endTime >= blockTime) {
        status = 1; // running
    }
    return status;
}

export const useIdo = () => {
    const fetchIdo = useCallback(async (address) => {
        const _WEB3 = createIdoWeb3(address);
        const idoContract = await ContractFactoryHof(_WEB3).create(Ido.abi, address);
        if (!idoContract) {
            return;
        }

        const blockTime = await getBlockTime();
        const [rate, minBuy, maxBuy, hardCap, softCap, capitalRaised, startTime, endTime] = await idoContract.methods.stats().call();

        const idoInfo = Object.values(Idos).find(item => item.address === address);
        const tokenDecimals = 10 ** (idoInfo.tokenDecimals || 18);


        let requiredFunds = 0;
        if (idoInfo !== 'PROXY_FUNDED' && idoContract.methods.requiredFunds) {
            requiredFunds = await idoContract.methods.requiredFunds().call().catch(() => true);
        }

        let whiteListManager;
        if (idoContract.methods.whitelistManagerAddress) {
            whiteListManager = await idoContract.methods.whitelistManagerAddress().call().catch(() => true);
        }

        let finalized = true
        if (idoInfo !== 'PROXY_FUNDED' && idoContract.methods.finalized) {
            finalized = await idoContract.methods.finalized().call().catch(() => true);
        }

        let tokenAddress
        if (idoInfo !== 'PROXY_FUNDED') {
            tokenAddress = await idoContract.methods.token().call().catch(() => null);
        }

        const ido = {
            minPurchase: minBuy / 1e18,
            maxPurchase: maxBuy / 1e18,
            capital: capitalRaised / 1e18,
            hardCap: hardCap / 1e18,
            rate: rate / tokenDecimals,
            softCap: softCap / 1e18,
            startTime: startTime * 1000,
            endTime: endTime * 1000,
            idoContract: address,
            token: tokenAddress,
            whiteListManager,
            requiredFunds: requiredFunds / tokenDecimals
        };

        if (ido.startTime > blockTime) {
            ido.status = 0; //started
        }

        if (ido.startTime <= blockTime && ido.endTime >= blockTime) {
            ido.status = 1; // running
            if (ido.capital >= ido.hardCap) {
                ido.status = 2; //ended
                ido.endTime = blockTime;
            }
        }

        if (ido.endTime < blockTime) {
            if (ido.capital >= ido.softCap) {
                ido.status = 2; // ended
            }

            if (ido.capital < ido.softCap) {
                ido.status = 3; //failed
            }
        }

        ido.canClaim = ido.status === 2 && finalized;

        return ido;
    }, []);
    return fetchIdo;
};

export const useIsAdmin = (idoContractAddress) => {
    const idoContract = useIdoContract(idoContractAddress);

    const fetchCapital = useCallback(async (account) => {

        if (!idoContract) {
            return false;
        }
        const hash = window.web3.utils.keccak256('MANAGER_ROLE');
        const status = await idoContract.methods.hasRole(hash, account).call();

        return status;

    }, [idoContract]);

    return fetchCapital;
};

export const useIsWhiteListed = (idoAddress) => {
    const idoContract = useIdoContract(idoAddress);

    const fetchCapital = useCallback(async (account) => {
        if (!idoContract) {
            return true;
        }
        const status = await idoContract.methods.isWhitelisted(account).call().catch(() => true);
        return status;
    }, [idoContract]);

    return fetchCapital;
};

export const useInvestedAmount = (address) => {
    const idoContract = useIdoContract(address);

    const fetchCapital = useCallback(async (account) => {
        if (!idoContract) {
            return 0;
        }
        const amount = await idoContract.methods.investments(account).call();
        return amount / 1e18;

    }, [idoContract]);

    return fetchCapital;
};

export const useBalanceAmount = (address) => {
    const idoContract = useIdoContract(address);

    const fetchCapital = useCallback(async (account) => {
        if (!idoContract) {
            return 0;
        }

        const idoInfo = Object.values(Idos).find(item => item.address === address);
        const tokenDecimals = 10 ** (idoInfo.tokenDecimals || 18);

        const amount = await idoContract.methods.balance(account).call();
        return amount / tokenDecimals;

    }, [idoContract, address]);

    return fetchCapital;
};

export const useIdoAdminActions = (contract, account) => {
    const idoContract = useIdoContract(contract, window.web3);
    const [, refreshIdo] = useRefreshIdo();

    const resultHandler = useCallback(async result => {
        refreshIdo(contract);
        return result;
    }, [refreshIdo, contract]);

    return useOwnerHandles(account, idoContract, resultHandler);
};


export const useIdoActions = (address, account) => {
    const [, refreshIdo] = useRefreshIdo();
    const [, refreshInvestmentAmount] = useRefreshInvestmentAmount(address);
    const [, refreshBalance] = useRefreshUserBalanceAmount(address);
    const idoContract = useIdoContract(address, window.web3);

    const resultHandler = useCallback(async result => {
        refreshIdo(address);
        refreshBalance(account);
        refreshInvestmentAmount(account);
        return result;
    }, [refreshIdo, refreshBalance, refreshInvestmentAmount, account, address]);

    return useHandles(account, idoContract, resultHandler);
};

export const useHandles = (account, idoContract, handler) => {
    let swapAndBuy, buyWithBase, claim, refund, emergencyWithdraw;

    if (!idoContract) {
        return { swapAndBuy, buyWithBase, claim, refund, emergencyWithdraw };
    }

    claim = async () => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        await idoContract.methods.claim().estimateGas(args);

        const result = idoContract.methods.claim().send(args);
        return result.then(handler);
    };

    refund = async () => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };
        await idoContract.methods.refund().estimateGas(args);
        const result = idoContract.methods.refund().send(args);

        return result.then(handler);
    };

    emergencyWithdraw = async () => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };
        await idoContract.methods.emergencyWithdrawl().estimateGas(args);
        const result = idoContract.methods.emergencyWithdrawl().send(args);

        return result.then(handler);
    };


    const idoInfo = Object.values(Idos).find(item => item.address === idoContract._address);
    const nativeCoin = getNativeCoin(idoInfo.chainId);
    if (idoInfo.validationSymbol === nativeCoin.symbol) {
        swapAndBuy = async (tokenAddress, amount, minAmount) => {
            const _contract = await getNativeSwapAbi(idoContract._address);
            const gasPrice = await window.web3.eth.getGasPrice();
            const args = {
                from: account,
                gasPrice: gasPrice,
                value: 0
            };

            await _contract.methods.swapToBaseAndBuy(
                tokenAddress,
                window.web3.utils.toWei('' + amount),
                window.web3.utils.toWei('' + minAmount)
            ).estimateGas(args);

            const result = _contract.methods.swapToBaseAndBuy(
                tokenAddress,
                window.web3.utils.toWei('' + amount),
                window.web3.utils.toWei('' + minAmount)
            ).send(args);

            return result.then(handler);
        }

        buyWithBase = async (amount) => {
            const _contract = await getNativeSwapAbi(idoContract._address) ;
            const gasPrice = await window.web3.eth.getGasPrice();
            const args = {
                from: account,
                gasPrice: gasPrice,
                value: window.web3.utils.toWei('' + amount)
            };

            await _contract.methods.buyWithBase().estimateGas(args);
            const result = idoContract.methods.buyWithBase().send(args);

            return result.then(handler);
        }

    } else {
        swapAndBuy = async (token, amount, minAmount, baseIsNative) => {
            const _contract = await getERCSwapAbi(idoContract._address)

            const gasPrice = await window.web3.eth.getGasPrice();
            const args = {
                from: account,
                gasPrice: gasPrice,
                value: baseIsNative ? window.web3.utils.toWei('' + amount) : 0
            };
            if (baseIsNative) {
                token = ZERO_ADDRESS;
            }

            await _contract.methods.swapToBaseAndBuy(
                token,
                window.web3.utils.toWei('' + amount),
                window.web3.utils.toWei('' + minAmount)
            ).estimateGas(args);

            const result = _contract.methods.swapToBaseAndBuy(
                token,
                window.web3.utils.toWei('' + amount),
                window.web3.utils.toWei('' + minAmount)
            ).send(args);

            return result.then(handler);
        };

        buyWithBase = async (amount) => {
            const _contract = await getERCSwapAbi(idoContract._address)

            const gasPrice = await window.web3.eth.getGasPrice();
            const args = {
                from: account,
                gasPrice: gasPrice,
            };

            await _contract.methods.buyWithBase(window.web3.utils.toWei('' + amount)).estimateGas(args);
            const result = _contract.methods.buyWithBase(window.web3.utils.toWei('' + amount)).send(args);
            return result.then(handler);
        };


    }

    return { swapAndBuy, buyWithBase, claim, refund, emergencyWithdraw }
}


export const useOwnerHandles = (account, idoContract, handler) => {
    let fundPresale, finalizePresale;
    if (!idoContract) {
        return { fundPresale, finalizePresale };
    }

    fundPresale = async () => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        await idoContract.methods.fund().estimateGas(args);
        const result = idoContract.methods.fundFairLaunch().send(args);
        return result.then(handler);
    };


    finalizePresale = async () => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        await idoContract.methods.finalizePresale().estimateGas(args);
        const result = idoContract.methods.finalizePresale().send(args);
        return result.then(handler);

    };

    return { fundPresale, finalizePresale };

}


const getERCSwapAbi = async (address) => {
    const _contract = new window.web3.eth.Contract([{
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "buyWithBase",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [
            {
                "internalType": "contract IERC20",
                "name": "token",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "minAmount",
                "type": "uint256"
            }
        ],
        "name": "swapToBaseAndBuy",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    }], address);

    return _contract;
}

const getNativeSwapAbi = async (address) => {
    return new window.web3.eth.Contract([{
        "inputs": [],
        "name": "buyWithBase",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [
            {
                "internalType": "contract IERC20",
                "name": "token",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "minAmount",
                "type": "uint256"
            }
        ],
        "name": "swapToBaseAndBuy",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    }], address);
}



